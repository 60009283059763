import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import NewsletterGen from "../components/newslettergen"


export default function WhoTemplate() {
  return (
    <Layout breadcrumbs={["Events"]}>
          <div className="carousel-container">
      <div className="group-5-about-us-events" >
      <div className="connecting-the-blockchain-together" >Join Our Events</div>
      <p className="about-us-carousel">Whether in-person or online, we hope you’ll join us for an upcoming event.<br /><br />Our in-person events are top-tier networking opportunities, where you’ll learn from trailblazers in the blockchain industry.<br /><br />Our online events include workshops, webinars, and panels that can be watched from anywhere—either live, or on-demand.<br /><br />Be inspired. Learn what’s new. And connect with our vibrant community. Join us!</p>      
      </div> 
        <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1701203905/events/thumbnail_header_Events_Page_s0bkmv.jpg" class="group-3" />
    </div>  
    <div className="container-noflex">
      <div className="group-events-header">
        <h1>Upcoming Events</h1>
      </div>
 
       <div className="group-events-hosted-new-upcoming">
          <div className="who-we-are-new-fixed">	
		  <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1742810588/q2-2025_ehxkvm.jpg"  className="image-events-new" />
             <div className="container-events-text-center"><strong>Q2 2025 Events</strong></div>  		  
            <div className="container-events-text-left-new"><div><strong>BBA Big Bites: Mining</strong> (Virtual) – Focused on the business of mining and path forward</div></div>
			
			<div className="events-text-left-new"><div><strong>Institutional Series</strong> – featuring a MA federal lawmaker and two/three panels highlighting the 2025 outlook and path forward for institutions navigating the current regulatory environment.</div></div>
			
			<div className="container-events-text-left-new"><div><strong>AI/Blockchain Workshop</strong> – Focused on the crossover of AI and blockchain technologies and Institutional use cases</div></div>
			
			<div className="events-text-left-new"><div><strong>BBA Small Bites: DeFi</strong> (Virtual) – Progress and the path forward for DeFi.</div></div>
			
			<div className="container-events-text-left-new"><div><strong>BBA Startup Day with Pitch Competition</strong></div></div>                         
          </div>    
          <div className="who-we-are-new-fixed">	
			<img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1742810588/q3-2025_x0e0au.jpg"  className="image-events-new" />		  
			
			<div className="container-events-text-center"><strong>Q3 2025 Events</strong></div> 		  
            
			<div className="events-text-left-new"><div><strong>BBA/Fidelity (FCAT) on Tokenization & Summer Social</strong> – A fireside chat and panel session on advances in the tokenization of traditional financial products.</div></div>
			
			<div className="container-events-text-left-new"><div><strong>Workshop: Cyber Security for Blockchain & Emerging Technology</strong></div></div>	
			
			<div className="events-text-left-new"><div><strong>Small Bites: Gaming (Virtual)</strong></div></div>
			
			<div className="container-events-text-left-new"><div><strong>Institutional Event</strong> – A fireside discussion with federal and state lawmakers followed by panels with leading digital asset native and traditional firms, highlighting use cases and related benefits.</div></div>               
           
		   <p className="events-text-left-new"><br /></p>
           
		   <p className="container-events-text-left-new"><br /></p>
		  </div>            
		  <div className="who-we-are-new-fixed">			 
		  <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1742810588/q4-2025_u1cd4i.jpg"  className="image-events-new" />
            <div className="container-events-text-center"><strong>Q4 2025 Events</strong></div> 
			
			<div className="container-events-text-left-new"><div><strong>Big Bites: DePin (Virtual)</strong></div></div> 
			
			<div className="events-text-left-new"><div><strong>Small Bites: Staking & Slashing (Virtual)</strong></div></div>
			
			<div className="container-events-text-left-new"><div><strong>University Series</strong></div></div> 
			
			<div className="events-text-left-new"><div><strong>Big Bites: Crypto Accounting (Virtual)</strong></div></div>
			
			<div className="container-events-text-left-new"><div><strong>BBA/TABB Group Institutional Event</strong> – Annual event, which highlights insights to the regulatory and institutional adoption of blockchains and digital assets. Two keynotes and three panels on the navigation, advancement, and use cases of these technologies.</div></div>
			
			<div className="events-text-left-new"><div><strong>BBA/Fidelity (FCAT) - Annual Holiday Party</strong></div></div>               
          </div>  		  
        </div>    	  
	    <div className="group-events-hosted-new">
          <div className="who-we-are-new">
               <a href="https://www.meetup.com/boston-blockchain-association/" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1733337031/events/meetup_logo_grn3og.png" className="image-events-logos" /></a>
             <p className="container-events-text">To join upcoming events, join our Meetup group, where all new events are posted.</p>       
             <a href="https://www.meetup.com/boston-blockchain-association/" target="_blank" className="full-width"><button className="events-button"><b>See Upcoming Events</b></button></a>      
          </div>
          <div className="who-we-are-new">
             <a href="https://www.eventbrite.com/o/boston-blockchain-association-55182169053" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1733337029/events/Eventbrite_Logo_otkaub.png"  className="image-events-logos" /></a>             
              <p className="container-events-text">To register for a ticketed event, use our Eventbrite page.</p>  
             <a href="https://www.eventbrite.com/o/boston-blockchain-association-55182169053" target="_blank" className="full-width"><button className="events-button"><b>Register for Ticketed Event</b></button></a>                                  
          </div>   		  
          <div className="who-we-are-new">
            <a href="https://www.youtube.com/channel/UCO0fWrhXoq_x2_GdnmahVdQ" target="_blank"><img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1733337026/events/youtube_logo_brmdjp.png"  className="image-events-logos" /></a>
             <p className="container-events-text">Most past online events are archived on our BBA YouTube channel.</p> 
             <a href="https://www.youtube.com/channel/UCO0fWrhXoq_x2_GdnmahVdQ" target="_blank" className="full-width"><button className="events-button"><b>View Previous Events</b></button></a>                          
          </div>                    
      </div>       
    </div>  
   
    <div className="container-noflex-events">
        <div className=" who-we-are-new-volunteer-cta-new">
          <div className=" who-we-are-new-volunteer-content-new">
            <h2>Join Our Newsletter</h2>
            <p>To get notified about all the blockchain events in Boston (BBA and beyond), sign up for our weekly newsletter.</p>
          </div>
          <div className="who-we-are-new-volunteer-img-hand">
            <img src="https://res.cloudinary.com/dfwexsly7/image/upload/v1701526498/events/1000_F_361449961_i1QO8dNVVvwzGHdEFI1j7f67GbBWHZXq_1_xequcv.png" />
          </div>         
         </div>  
         <div className="newsletter-container-events">
      <h3 className="subscribe-to-the-newsletter">Subscribe to the Newsletter</h3>
	  <div className="subscribe-to-the-boston-blockchain-association-newsletter-and-get-notified-on-the-latest-blockchain-events-job-openings-opportunities-and-more" >Subscribe to the Boston Blockchain Association newsletter and get notified on the latest blockchain events, job openings, opportunities, and more. </div>
      <div className="newsletter-form-container">
	  <NewsletterGen />
	  </div>
    </div>         
    </div>    
      </Layout>
  )
}     
